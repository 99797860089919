/**********************************************
*** © Linguagens Script > DEIS > ISEC > IPC 
*** Jogo de Memória em React
*** Estilos para game-panel.componente.jsx 
**********************************************/

#game-panel {
  width: 100%;
  margin: 0 auto;
}

#game {
  width: 80%;
  min-width: 200px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 20px;
  grid-template-columns: repeat(10,auto);
  gap: 1px;
  display: grid;
  justify-content: center;
  perspective: 1000px;
  user-select: none;
}

#game.avancado {
  width: 80%;
  grid-template-columns: repeat(12,auto);
}

#game.intermedio {
  width: 80%;
  grid-template-columns: repeat(11,auto);
}

.flex-container{
  display: flex;
}

.flex-child.primeiro{
  width: auto;
  margin-top: 10px;
  margin-left: 20px;
  order: 2;
}

.flex-child.primeiro h3{
  width: 200px;
  font-size: 25px;
}

.flex-child.primeiro h2{
  font-size: 15px;
}

.esconder{
  display: none !important;
}

.encontrada{
  text-decoration: line-through;
  text-decoration-color:red;
}
