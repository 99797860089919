/**********************************************
*** © Linguagens Script > DEIS > ISEC > IPC 
*** Jogo de Memória em React
*** Estilos Index.js
**********************************************/

:root {
  --globalColor: #404487;
  --messageColor: rgb(11, 11, 241);
  --boxshadow0: 0px 0px 0px;
  --top10Color: #404487;
  --gameOverColor: #404487;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(#555, rgba(0, 0, 0, 0.1)) fixed;
  font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
  display: flex;
  justify-content: center;
}

select,
label,
dd,
button {
  padding: 5px;
  border-radius: 5px;
}

select,
button {
  cursor: pointer;
}
