/**********************************************
*** © Linguagens Script > DEIS > ISEC > IPC 
*** Jogo de Memória em React
*** Estilos para card.component.jsx 
**********************************************/

.letra {
  cursor: pointer;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  position: relative;
  width: 50px;
  height: 50px;
  border-style: solid;
  border-width: 2px;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  transition: transform 0.4s;
  background-color: rgb(243, 243, 243);
}

.letra-front {
  position: relative;
  text-align: center;
  bottom: 15px;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}


.grayscale {
  filter: grayscale(1) opacity(0.5);
}

.letra:hover {
  border: 2px solid gray;
  box-shadow: var(--boxshadow0);
}

.letra-front.clicked {
  background-color: rgb(209, 0, 0) !important;
}

.letra-front.completa {
  background-color:darkslategray !important;
}

.no-events {
  pointer-events: none;
}